<template>

<!-- EDIT HERE -->

<div class="container">
<section class="section rules text-center" id="rules">
<div class="tabs">
  <div class="spoon is-medium is-primary">Rules</div>
</div>
<!-- THIS TEXT IS COPYPASTED FROM MOOSE.GG, perhaps you need to change it! >>> -->
<h3>VAC/Game Bans</h3>
<ul class="text-start">
<li>Tüm hesaplarda 1 VAC veya Oyun Yasağı'na izin verilir. / 1 VAC or Game Ban allowed across all accounts.</li>
<li>Herhangi bir VAC veya Oyun Yasağı 1 yıldan eski olmalıdır (365 gün). / Any VAC or Game Ban must be over 1 year old (365 days).</li>
<li>Bu kuralın kesinlikle HİÇBİR istisnası yoktur. Cevap her zaman hayır olacaktır, bu yüzden sormayın. / There are absolutely NO exceptions to this rule. The answer will always be no so do not ask.</li>
</ul>
<h3>Irkçılık & Siyaset / Racism & Politics</h3>
<ul class="text-start">
<li>Herhangi bir ırkçılık veya siyaset tartışmasına karşı kesinlikle sıfır toleransımız vardır. Günlük hayatınızda istediğinizi yapabilirsiniz, ancak sunucularımızda buna tolerans gösterilmeyecektir. / We have absolutely zero tolerance for any form of racism or politics. You can do whatever you like in your day to day lives, but it will not be tolerated on our servers.</li>
<li>Chat ekranına yazmayın, tabelaya yazmayın/çizmeyin ve sesli sohbette söylemeyin. Adminler sürekli izliyor ve eğer biz görürsek yasaklanacaksınız. / Don't type it, don't write/draw on a sign, and don't say it in voice chat. Admins spectate constantly and if we see it, you will be banned.</li>
<li>Very rarely do we warn. By playing on our servers you are consenting to our rules and understand the consequences for not following them. / Very rarely do we warn. By playing on our servers you are consenting to our rules and understand the consequences for not following them.</li>
</ul>
<h3>Yetkililere ve Oyunculara Saygısızlık / Disrespecting Staff & Players</h3>
<ul class="text-start">
<li>Bu bir çevrimiçi oyun ve HER ZAMAN çöp konuşmaları olacak. Anlıyoruz. Ancak bu, tüm ahlakınızı kapıda bırakabileceğiniz anlamına gelmiyor. Sağduyunuzu kullanın ve diğer oyunculara veya yöneticilere kişisel saldırılarda bulunmayın. / This is an online game and there will ALWAYS be trash talk going on. We get it. However, that doesn't mean you get to leave all of your morals at the door. Use common sense and do not go after other players or admins with personal attacks.</li>
<li>Eğer arkadaşınız kuralları çiğnediyse ve bunun için cezalandırıldıysa, kararımız hakkında bir yöneticiyle tartışmaya çalışmayın. Yeni bir sunucu bulun ve eylemlerinizin sonuçlarını kabul edin. / If your friend broke the rules and was punished for it, don't try to argue with an admin about our decision. Go find a new server and accept the consequences for your actions.</li>
<li>İyi bir insan ol. Bunun Rust olduğunu biliyorum ama her açıdan berbat bir insan olmana gerek yok. / Be a decent human being. I know this is Rust, but there is no need to be an all around shitty person.</li>
</ul>
<!-- <<< THIS TEXT IS COPYPASTED FROM MOOSE.GG, perhaps you need to change it! -->
</section>
</div>

<!-- EDIT HERE -->

</template>


<script>
export default {
  name: 'rulesSection'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
div.store {
	padding-top: 10rem;
}
#rules > h3 {
	color: var(--color-highlight);
}
ul.text-start > li {
	color: var(--color-mute);
}
</style>
